import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ProtectedRoute from "../../router/protected-route";
import AppLayout from "layout/appLayout";
import { AnimatePresence } from "framer-motion";
import pMinDelay from "p-min-delay";

const Login = React.lazy(() =>
  pMinDelay(
    import(/* webpackChunkName: "views-auth-login" */ "../../views/auth/login"),
    1500
  )
);
const Home = React.lazy(() =>
  pMinDelay(
    import(/* webpackChunkName: "views-home" */ "../../views/home"),
    1500
  )
);
const Regist = React.lazy(() =>
  pMinDelay(
    import(
      /* webpackChunkName: "views-auth-regist" */ "../../views/activation-account/regist"
    ),
    1500
  )
);
const ActivationAccount = React.lazy(() =>
  pMinDelay(
    import(
      /* webpackChunkName: "views-activationAccount" */ "../../views/activation-account"
    ),
    1500
  )
);
const ReadQr = React.lazy(() =>
  pMinDelay(
    import(
      /* webpackChunkName: "views-activationAccount" */ "../../views/read-qr"
    ),
    1500
  )
);
const CheckIn = React.lazy(() =>
  pMinDelay(
    import(
      /* webpackChunkName: "views-activationAccount" */ "../../views/check-in"
    ),
    1500
  )
);
const Notifications = React.lazy(() =>
  pMinDelay(
    import(
      /* webpackChunkName: "views-notifications" */ "../../views/notifications"
    ),
    1500
  )
);
const Events = React.lazy(() =>
  pMinDelay(
    import(/* webpackChunkName: "views-events" */ "../../views/events"),
    1500
  )
);
const EventDetails = React.lazy(() =>
  pMinDelay(
    import(/* webpackChunkName: "views-events" */ "../../views/events/details"),
    1500
  )
);
const Calendar = React.lazy(() =>
  pMinDelay(
    import(/* webpackChunkName: "views-calendar" */ "../../views/calendar"),
    1500
  )
);
const NotFoundPage = React.lazy(() =>
  pMinDelay(
    import(
      /* webpackChunkName: "views-route-not-found" */ "../../views/route-not-found"
    ),
    1500
  )
);
const RecoverAccount = React.lazy(() =>
  pMinDelay(
    import(
      /* webpackChunkName: "views-recover-account" */ "../../views/recoverAccount"
    ),
    1500
  )
);
const UserDetails = React.lazy(() =>
  pMinDelay(
    import(
      /* webpackChunkName: "views-recover-account" */ "../../views/user/details"
    ),
    1500
  )
);
const Faqs = React.lazy(() =>
  pMinDelay(
    import(/* webpackChunkName: "views-recover-account" */ "../../views/faqs"),
    1500
  )
);
const News = React.lazy(() =>
  pMinDelay(
    import(/* webpackChunkName: "views-recover-account" */ "../../views/news"),
    1500
  )
);
const NewsDetails = React.lazy(() =>
  pMinDelay(
    import(
      /* webpackChunkName: "views-recover-account" */ "../../views/news/details"
    ),
    1500
  )
);
const Quiz = React.lazy(() =>
  pMinDelay(
    import(/* webpackChunkName: "views-quiz" */ "../../views/quiz"),
    1500
  )
);
const PlayQuiz = React.lazy(() =>
  pMinDelay(
    import(/* webpackChunkName: "views-quiz" */ "../../views/quiz/play"),
    1500
  )
);
const BeSupport = React.lazy(() =>
  pMinDelay(
    import(/* webpackChunkName: "views-quiz" */ "../../views/beSupport"),
    1500
  )
);

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AppLayout>
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location?.pathname}>
          <Route index element={<Navigate to="/home" />} />
          <Route exact path="/404">
            <Route index element={<NotFoundPage />} />
          </Route>
          <Route exact path="/login">
            <Route index element={<Login />} />
          </Route>
          <Route exact path="/regist">
            <Route index element={<Regist />} />
          </Route>
          <Route exact path="/accountActivation">
            <Route index element={<ActivationAccount />} />
          </Route>
          <Route exact path="/recoverAccount">
            <Route index element={<RecoverAccount />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route element={<Home />} path="/home" exact />
            <Route exact path="/read-qr">
              <Route index element={<ReadQr />} />
            </Route>
            <Route exact path="/check-in/:code">
              <Route index element={<CheckIn />} />
            </Route>
            <Route exact path="/notifications">
              <Route index element={<Notifications />} />
            </Route>
            <Route exact path="/events">
              <Route index element={<Events />} />
            </Route>
            <Route exact path="/events/details/:id">
              <Route index element={<EventDetails />} />
            </Route>
            <Route exact path="/user/details/:id">
              <Route index element={<UserDetails />} />
            </Route>
            <Route exact path="/agenda">
              <Route index element={<Calendar />} />
            </Route>
            <Route exact path="/news">
              <Route index element={<News />} />
            </Route>
            <Route exact path="/news/details/:id">
              <Route index element={<NewsDetails />} />
            </Route>
            <Route exact path="/faqs">
              <Route index element={<Faqs />} />
            </Route>
            <Route exact path="/quiz">
              <Route index element={<Quiz />} />
            </Route>
            <Route exact path="/quiz/play/:id">
              <Route index element={<PlayQuiz />} />
            </Route>
            <Route exact path="/beSupport">
              <Route index element={<BeSupport />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </AnimatePresence>
    </AppLayout>
  );
};

export default AnimatedRoutes;
