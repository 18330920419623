import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../helpers/apiHelper';
import { userDetailsByUsername } from '../auth';
import { fetchHighlights } from '../highlight';

// ** Fetch Events
export const fetchEvents = createAsyncThunk('events/fetchDataEvents', async (params, { getState, dispatch }) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const response = await api.get(`${process.env.REACT_APP_API_URL}api/events/list?order=${params.order}&userId=${params.userId}`)
  return response.data;
})

// ** List User Events
export const listUserEvents = createAsyncThunk('events/listUserEvents', async (params) => {
  const response = await api.get(`${process.env.REACT_APP_API_URL}api/userEvents/list`,{params})
  return response.data;
})

// ** RegistUser event
export const registUserEvent = createAsyncThunk('events/registUserEvent', async (params, { getState, dispatch }) => {
  const values = params
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const config = { headers: { "Content-Type": "multipart/form-data" }};
  const response = await api.post(`api/userEvents/registerUser`, values, config)
  
  const userParams = {accessToken : params.accessToken, userId: getState().auth.loggedUser.id, order: 'ASC'}
  dispatch(fetchEvents(userParams))
  dispatch(fetchHighlights(userParams))

  return response.data
})

// ** UnRegistUser event
export const unRegistUserEvent = createAsyncThunk('events/unRegistUserEvent', async (params, { getState, dispatch }) => {
  const values = params
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const config = { headers: { "Content-Type": "multipart/form-data" }};
  const response = await api.post(`api/userEvents/unregisterUser`, values, config)

  const userParams = {accessToken : params.accessToken, userId: getState().auth.loggedUser.id, order: 'ASC'}
  dispatch(fetchEvents(userParams))
  dispatch(fetchHighlights(userParams))

  return response.data
})

// ** CheckIn event
export const checkInEvent = createAsyncThunk('events/checkInEvent', async (params, { getState, dispatch }) => {
  const values = params
  
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const config = { headers: { "Content-Type": "multipart/form-data" }};
  const response = await api.post(`api/userEvents/checkIn`, values, config)

  const userParams = {accessToken : params?.accessToken, username: getState().auth?.loggedUser?.username}
  dispatch(userDetailsByUsername(userParams))

  return response.data
})

// ** Event Details 
export const eventDetails = createAsyncThunk('events/eventDetailsCode', async (params, { getState, dispatch }) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const response = await api.get(`api/events/details/${params.id}`)

  return response.data
})

// ** Fetch Event Images
export const fetchEventImages = createAsyncThunk('events/fetchEventImages', async (params, { getState, dispatch }) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const response = await api.get(`api/eventImages/detailsUser?eventId=${params.id}&userId=${params.userId}`)
  return response.data;
})

// ** ImageFeedback
export const imageFeedback = createAsyncThunk('events/imageFeedback', async (params, { getState, dispatch }) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const config = { headers: { "Content-Type": "multipart/form-data" }}
  const response = await api.post("api/image/feedback", params, config)
  return response.data;
})

// ** Event Details with User
export const eventDetailsUser = createAsyncThunk('events/eventDetailsCode', async (params, { getState, dispatch }) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const response = await api.get(`api/events/detailsUser?id=${params.id}&userId=${params.userId}`)

  return response.data
})

// ** Event Details With Code
export const eventDetailsCode = createAsyncThunk('events/eventDetailsCode', async (params, { getState, dispatch }) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const response = await api.get(`api/events/detailsCode/${params.eventCode}`)

  return response.data
})

// ** Events Per User
export const eventsPerUser = createAsyncThunk('events/eventsPerUser', async (params, { getState, dispatch }) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const response = await api.get(`api/userEvents/eventsPerUser/${params.id}`)

  return response.data
})

export const appEventsSlice = createSlice({
  name: 'events',
  initialState: {
    data: [],
    eventsPerUser: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.data = action.payload.Data
    })
    builder.addCase(eventsPerUser.fulfilled, (state, action) => {
      state.eventsPerUser = action.payload.Data
    })
    builder.addCase(listUserEvents.fulfilled, (state, action) => {
      state.userEvents = action.payload.Data
    })
  }
})

export default appEventsSlice.reducer