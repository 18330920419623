import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../helpers/apiHelper';
// ** Axios Imports
import axios from 'axios'

// ** Fetch Toolbox Files
export const fetchDataToolbox = createAsyncThunk('toolbox/fetchDataToolbox', async (params) => { 
  
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken}
  const response = await api.get('api/toolbox/list')

  return response.data;
})

export const appToolboxSlice = createSlice({
  name: 'toolbox',
  initialState: {
    data: false
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDataToolbox.fulfilled, (state, action) => {
      state.data = action.payload.Data
    })
  }
})

export default appToolboxSlice.reducer
