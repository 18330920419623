import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../helpers/apiHelper';
// ** Axios Imports
import axios from 'axios'

// ** Add Suggestion

export const addSuggestion = createAsyncThunk('events/addSuggestion', async (params, { getState, dispatch }) => {
  const values = params
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const config = { headers: { "Content-Type": "multipart/form-data" }};

  const response = await api.post(`api/suggestions/new`, values, config)
  

  return response.data
})

export const appSuggestionsSlice = createSlice({
  name: 'suggestions',
  initialState: {
    data: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(addSuggestion.fulfilled, (state, action) => {
      state.data = action.payload.Data
    })
  }
})

export default appSuggestionsSlice.reducer
