import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../helpers/apiHelper';
// ** Axios Imports
import axios from 'axios'

// ** Fetch Categories
export const fetchDataCategory = createAsyncThunk('categories/fetchDataCategory', async (params) => { 
  
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken}
  const response = await api.get(`api/categories/list?order=${params.order}`)

  return response.data;
})

export const appCategoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    data: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDataCategory.fulfilled, (state, action) => {
      state.data = action.payload.Data
    })
  }
})

export default appCategoriesSlice.reducer
