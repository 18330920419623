
export const lightTheme = {
  dark_blue : "#00427A",
  light_blue : "#3492F4",
  light_blue_opacity : "rgba(52, 146, 244, 0.5)",
  background_gradient1: "linear-gradient(#ffffff,#F9FAFF)",
  blue_grey : "#899CB0",
  button_gradient1: "linear-gradient(to bottom right, #3492F4,#5672ED)",
  input_borders: "#EFF3F7",
  checked_borders: "#3492F4",
  check_buttons_background: "#ffffff",
  checked: "linear-gradient(to bottom, #3492F4,#5672ED)",
  cards: "#ffffff",
  white: "#ffffff",
  errorsForm: "#BD2130",
  border_primary: "#5672ED",
  orange_gradient: "linear-gradient(to right, #FFBB00, #D87516)",
  blue_gradient: "linear-gradient(to right, #3492F4,#5672ED)",
  night_blue: "#112B46",
  be_better_orange: "#F28915",
  violet_blue: '#5664ED',
  disabled: "#ebecef",
  disabled_opacity : "rgba(255, 255, 255, 0.3)",
}

export const darkTheme = {

  dark_blue : "#00427A",
  light_blue : "#3492F4",
  light_blue_opacity : "rgba(52, 146, 244, 0.5)",
  background_gradient1: "linear-gradient(#ffffff,#F9FAFF)",
  blue_grey : "#899CB0",
  button_gradient1: "linear-gradient(to bottom right, #3492F4,#5672ED)",
  input_borders: "#EFF3F7",
  checked_borders: "#3492F4",
  check_buttons_background: "#ffffff",
  checked: "linear-gradient(to bottom, #3492F4,#5672ED)",
  cards: "#ffffff",
  white: "#ffffff",
  errorsForm: "#BD2130",
  night_blue: "#112B46",
  be_better_orange: "#F28915",
  violet_blue: '#5664ED',
  disabled: "#ebecef",
  disabled_opacity : "rgba(255, 255, 255, 0.3)",
}