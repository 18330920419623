import React from "react";
import { Router } from "./router";
import "./assets/scss/app.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

// const theme = createTheme({
//   typography: {
//     allVariants: {
//     fontFamily: [
//       "Nunito",
//     ].join(",")
//   }
//   }
// });

function App() {
  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          allVariants: {
            fontFamily: [
              "Nunito",
              "sans-serif"
            ].join(",")
          }
        },
      }),
  );
  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <Router />
      <ToastContainer
        limit={5}
        position="bottom-right"/>
    </div>
    </ThemeProvider>
  );
}
export default App;