import { lightTheme } from "config/colors";
import React from "react";
import Lottie from 'react-lottie';
import * as Loading from './loading';
import {Box,Typography} from '@mui/material'

const Spinner = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
  };

  return(
    <Box
      sx={{
        position: "absolute",
        height: '100vh',
        display: 'flex',
        width: '100vw',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0',
        margin: '0',
      }}
    >
      <Lottie options={defaultOptions} height={150} width={150} style={{width: "fit-content"}}/>
      <Typography noWrap variant='h4' color={lightTheme?.be_better_orange} sx={{ textTransform: 'capitalize', mt: 5}}>
        Be Better
      </Typography>
    </Box>
  ) 
};

export default Spinner;
