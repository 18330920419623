import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import {store, persistor} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import './assets/scss/vendor/bootstrap.min.css';
import './assets/scss/vendor/bootstrap.rtl.only.min.css';
import './i18n';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>  
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();