import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { lightTheme } from "../../../config/colors";
import { Typography } from "@mui/material";
import { ReactComponent as MenuBackground } from "assets/images/home/menu/result.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/store/apps/auth";
import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";
import "./menu.scss";

const Menu = ({ menuOpen, toggleMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const location = useLocation();

  const navTo = (url, params) => {
    navigate(url, params);
    toggleMenu();
  };

  const logoutApp = () => {
    dispatch(logout()).then((window.location.href = "/login"));
    toggleMenu();
  };

  useEffect(() => {
    const wrapper = document.getElementById("wrapper");
    const navLinks = document.getElementById("navLinks");
    if (navLinks) {
      if (menuOpen) {
        navLinks.style.display = "block";
        setTimeout(() => {
          navLinks.style.opacity = 1;
        }, [500]);
      } else {
        navLinks.style.opacity = 0;
        navLinks.style.transition = "0.5s all";
        setTimeout(() => {
          navLinks.style.display = "none";
        }, [1500]);
      }
    }
    if (wrapper) {
      if (menuOpen) {
        wrapper.classList.remove("wrapper");
        wrapper.classList.remove("wrapper-closed");
        wrapper.classList.add("wrapper-open");
      } else {
        wrapper.classList.remove("wrapper-open");
        wrapper.classList.add("wrapper-closed");
      }
    }
  }, [menuOpen]);

  return (
    <>
      <Row id="wrapper" className="wrapper">
        <MenuBackground />
      </Row>
      <div className="m-0 p-0 d-flex justify-content-center">
        <div
          id="navLinks"
          className="navLinks"
          style={{ color: lightTheme?.white }}
        >
          <Typography
            variant="h6"
            id="/home"
            onClick={() => navTo("/home")}
            sx={
              location?.pathname === "/home"
                ? {
                  mb: 3,
                  textDecoration: "underline 3px",
                  textUnderlineOffset: "10px",
                  textDecorationColor: lightTheme.be_better_orange,
                }
                : { mb: 3, textDecoration: "none" }
            }
          >
            {t("home.home")}
          </Typography>
          <Typography
            variant="h6"
            onClick={() => navTo("/events")}
            sx={
              location?.pathname === "/events"
                ? {
                  mb: 3,
                  textDecoration: "underline 3px",
                  textUnderlineOffset: "10px",
                  textDecorationColor: lightTheme.be_better_orange,
                }
                : { mb: 3, textDecoration: "none" }
            }
          >
            {t("events.schedule")}
          </Typography>
          <Typography
            variant="h6"
            onClick={() => navTo("/news")}
            sx={
              location?.pathname === "/news"
                ? {
                  mb: 3,
                  textDecoration: "underline 3px",
                  textUnderlineOffset: "10px",
                  textDecorationColor: lightTheme.be_better_orange,
                }
                : { mb: 3, textDecoration: "none" }
            }
          >
            {t("news.news")}
          </Typography>
          <Typography
            variant="h6"
            onClick={() => navTo("/quiz")}
            sx={
              location?.pathname === "/quiz"
                ? {
                  mb: 3,
                  textDecoration: "underline 3px",
                  textUnderlineOffset: "10px",
                  textDecorationColor: lightTheme.be_better_orange,
                }
                : { mb: 3, textDecoration: "none" }
            }
          >
            Quiz
          </Typography>
          {store?.loggedUser?.chefe &&
            <Typography
              variant="h6"
              onClick={() => navTo("/toolbox")}
              sx={
                location?.pathname === "/toolbox"
                  ? {
                    mb: 3,
                    textDecoration: "underline 3px",
                    textUnderlineOffset: "10px",
                    textDecorationColor: lightTheme.be_better_orange,
                  }
                  : { mb: 3, textDecoration: "none" }
              }
            >
              {t('toolbox.title')}
            </Typography>
          }
          <Typography
            variant="h6"
            onClick={() => navTo(`/user/details/${store.loggedUser?.id}`)}
            sx={
              location?.pathname === `/user/details/${store.loggedUser?.id}`
                ? {
                  mb: 3,
                  textDecoration: "underline 3px",
                  textUnderlineOffset: "10px",
                  textDecorationColor: lightTheme.be_better_orange,
                }
                : { mb: 3, textDecoration: "none" }
            }
          >
            {t("user.settings")}
          </Typography>
          <Typography variant="h6" onClick={() => logoutApp()}>
            {t("general.logout")}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Menu;
