/* eslint-disable no-else-return */
import React from 'react';
import { Outlet,Navigate } from 'react-router-dom';
import { useLocation } from 'react-router'
import { connect } from 'react-redux';

function ProtectedRoute({loggedUser}) {
  let isLogged = Object.entries(loggedUser).length === 0 ? false : true;
  const location =  useLocation();
  
  return (
    isLogged ? 
      <Outlet /> 
      : 
      <Navigate to="/login" state={{ from: location }} />
  )
}
  
const mapStateToProps = state => {
  return {
      loggedUser: state?.auth?.loggedUser,
  }
}

export default connect(mapStateToProps)(ProtectedRoute);
