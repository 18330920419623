import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../helpers/apiHelper';

// ** Fetch Highlights
export const fetchHighlights = createAsyncThunk('events/fetchHighlights', async (params, { getState, dispatch }) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const response = await api.get(`${process.env.REACT_APP_API_URL}api/highlights/list?order=${params.order}&userId=${params.userId}`)
  return response.data;
})

export const appHighlightsSlice = createSlice({
  name: 'highlights',
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchHighlights.fulfilled, (state, action) => {
      state.data = action.payload.Data
    })
  }
})

export default appHighlightsSlice.reducer