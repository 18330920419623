import axios from 'axios';
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { logout } from 'redux/store/apps/auth'
import { useDispatch } from 'react-redux'
import { toast } from "react-toastify";

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type' : 'application/json',
  }
});

const AxiosInterceptor = ({ children }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

    axiosConfig.interceptors.response.use(function (response) {
      return response
    }, function (error) {

      if(error.response.status === 401){
        dispatch(logout());
      }else{

        return Promise.reject(error)
      }
    })

  return children;
}

export default axiosConfig;
export { AxiosInterceptor }
