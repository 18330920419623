import React, {useState,useEffect, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { AxiosInterceptor } from "helpers/apiHelper";
import AnimatedRoutes from "components/router/animatedRoutes";
import Spinner from 'components/common/spinner'
import AnimatedRoutesMobile from "components/router/animatedRoutesMobile";

export const Router = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  return (
    <Suspense fallback={windowWidth < 576 ? null : <Spinner />}>
      <BrowserRouter>
        <AxiosInterceptor>
          {
            windowWidth < 576 ? <AnimatedRoutesMobile /> : <AnimatedRoutes />
          }
        </AxiosInterceptor>
      </BrowserRouter>
    </Suspense>
  );
};
