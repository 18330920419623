import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ProtectedRoute from "../../router/protected-route";
import AppLayout from "layout/appLayout";
import { AnimatePresence } from "framer-motion";

const Login = React.lazy(() =>
import(/* webpackChunkName: "views-auth-login" */ "../../views/auth/login"));
const Home = React.lazy(() =>
import(/* webpackChunkName: "views-home" */ "../../views/home"));
const Regist = React.lazy(() =>
import(/* webpackChunkName: "views-auth-regist" */ "../../views/activation-account/regist"));
const ActivationAccount = React.lazy(() =>
import(/* webpackChunkName: "views-activationAccount" */ "../../views/activation-account"));
const ReadQr = React.lazy(() =>
import(/* webpackChunkName: "views-activationAccount" */ "../../views/read-qr"));
const CheckIn = React.lazy(() =>
import(/* webpackChunkName: "views-activationAccount" */ "../../views/check-in"));
const Notifications = React.lazy(() =>
import(/* webpackChunkName: "views-notifications" */ "../../views/notifications"));
const Events = React.lazy(() =>
import(/* webpackChunkName: "views-events" */ "../../views/events"));
const EventDetails = React.lazy(() =>
import(/* webpackChunkName: "views-events" */ "../../views/events/details"));
const Calendar = React.lazy(() =>
import(/* webpackChunkName: "views-calendar" */ "../../views/calendar"));
const NotFoundPage = React.lazy(() =>
import(/* webpackChunkName: "views-route-not-found" */ "../../views/route-not-found"));
const RecoverAccount = React.lazy(() =>
import(/* webpackChunkName: "views-recover-account" */ "../../views/recoverAccount"));
const UserDetails = React.lazy(() =>
import(/* webpackChunkName: "views-recover-account" */ "../../views/user/details"));
const Faqs = React.lazy(() =>
import(/* webpackChunkName: "views-recover-account" */ "../../views/faqs"));
const News = React.lazy(() =>
import(/* webpackChunkName: "views-recover-account" */ "../../views/news"));
const NewsDetails = React.lazy(() =>
import(/* webpackChunkName: "views-recover-account" */ "../../views/news/details"));
const Quiz = React.lazy(() =>
import(/* webpackChunkName: "views-quiz" */ "../../views/quiz"));
const PlayQuiz = React.lazy(() =>
import(/* webpackChunkName: "views-quiz" */ "../../views/quiz/play"));
const BeSupport = React.lazy(() =>
import(/* webpackChunkName: "views-quiz" */ "../../views/beSupport"));

const AnimatedRoutesMobile = () => {
  const location = useLocation();

  return (
      <AppLayout>
        <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location?.pathname}>
          <Route index element={<Navigate to="/home" />} />
          <Route exact path="/404">
            <Route index element={<NotFoundPage />} />
          </Route>
          <Route exact path="/login">
            <Route index element={<Login />} />
          </Route>
          <Route exact path="/regist">
            <Route index element={<Regist />} />
          </Route>
          <Route exact path="/accountActivation">
            <Route index element={<ActivationAccount />} />
          </Route>
          <Route exact path="/recoverAccount">
            <Route index element={<RecoverAccount />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route element={<Home />} path="/home" exact />
            <Route exact path="/read-qr">
              <Route index element={<ReadQr />} />
            </Route>
            <Route exact path="/check-in/:code">
              <Route index element={<CheckIn />} />
            </Route>
            <Route exact path="/notifications">
              <Route index element={<Notifications />} />
            </Route>
            <Route exact path="/events">
              <Route index element={<Events />} />
            </Route>
            <Route exact path="/events/details/:id">
              <Route index element={<EventDetails />} />
            </Route>
            <Route exact path="/user/details/:id">
              <Route index element={<UserDetails />} />
            </Route>
            <Route exact path="/agenda">
              <Route index element={<Calendar />} />
            </Route>
            <Route exact path="/news">
              <Route index element={<News />} />
            </Route>
            <Route exact path="/news/details/:id">
              <Route index element={<NewsDetails />} />
            </Route>
            <Route exact path="/faqs">
              <Route index element={<Faqs />} />
            </Route>
            <Route exact path="/quiz">
              <Route index element={<Quiz />} />
            </Route>
            <Route exact path="/quiz/play/:id">
              <Route index element={<PlayQuiz />} />
            </Route>
            <Route exact path="/beSupport">
              <Route index element={<BeSupport />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
        </AnimatePresence>
      </AppLayout>
  );
};

export default AnimatedRoutesMobile;
