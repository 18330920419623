import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../helpers/apiHelper';
import { userDetailsByUsername } from 'redux/store/apps/auth'

// ** Fetch News
export const fetchNews = createAsyncThunk('events/fetchNews', async (params) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const response = await api.get(`${process.env.REACT_APP_API_URL}api/news/list?order=${params.order}&userId=${params.userId}`)
  return response.data;
})

// ** News Details 
export const newsDetails = createAsyncThunk('news/newsDetails', async (params) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const response = await api.get(`api/news/details/${params.id}`)

  return response.data
})

// ** News Details 
export const newsDetailsUser = createAsyncThunk('news/newsDetailsUser', async (params) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const response = await api.get(`api/news/detailsUser?newId=${params.newId}&userId=${params.userId}`)
  return response.data
})

// ** News feedBack
export const newFeedBack = createAsyncThunk('news/newFeedBack', async (params, {dispatch}) => {
  const values = params
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params?.accessToken }
  const config = { headers: { "Content-Type": "multipart/form-data" }};
  const response = await api.post("api/userNews/feedback", values, config)

  dispatch(newsDetailsUser({accessToken: params?.accessToken, newId: params?.newId, userId: params?.userId}))
  dispatch(userDetailsByUsername({accessToken: params?.accessToken, username : params?.username}))

  return response.data
})

export const appNewsSlice = createSlice({
  name: 'news',
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchNews.fulfilled, (state, action) => {
      state.data = action.payload.Data
    })
  }
})

export default appNewsSlice.reducer