import { createSlice  } from '@reduxjs/toolkit';
// import api from '../../../helpers/apiHelper';
// // ** Axios Imports
// import axios from 'axios'

// ** Fetch Users

export const appThemeSlice = createSlice({
  name: 'theme',
  initialState: {
    theme: "lightTheme"
  },
  
  reducers: {},
  // extraReducers: builder => {
  //   builder.addCase((state, action) => {
  //     state.data = action.payload.Data
  //   })
  // }
})

export default appThemeSlice.reducer
