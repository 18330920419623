import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../helpers/apiHelper';
import { userDetailsByUsername } from '../auth';

// ** Fetch Categories
export const fetchCategories = createAsyncThunk('quiz/fetchCategories', async (params) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const response = await api.get(`${process.env.REACT_APP_API_URL}api/quiz/categories/list?order=${params.order}`)
  return response.data;
})

// ** Fetch Quiz
export const fetchQuiz = createAsyncThunk('quiz/fetchQuiz', async (params) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const response = await api.get(`${process.env.REACT_APP_API_URL}api/quiz/list?order=${params.order}`)
  return response.data;
})

// ** Quiz Details 
export const quizDetails = createAsyncThunk('quiz/quizDetails', async (params) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const response = await api.get(`api/quiz/details/${params.id}`)
  return response.data
})

// ** Quiz Details 
export const quizHistoryDetails = createAsyncThunk('quiz/quizHistoryDetails', async (params) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const response = await api.get(`api/quiz/historyDetails/${params.id}`)
  return response.data
})

// ** Quiz Play 
export const quizPlay = createAsyncThunk('quiz/quizPlay', async (params) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken }
  const response = await api.get(`api/quiz/play/${params.id}`)
  return response.data
})

// ** Quiz Submit
export const submitQuiz = createAsyncThunk('quiz/submitQuiz', async (params, { getState, dispatch }) => {
  const values = params
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params?.accessToken }
  const config = { headers: {"Content-Type": "text/plain" }};
  const response = await api.post("api/quiz/submitQuiz", values, config)

  const userParams = {accessToken : params?.accessToken, username: getState().auth.loggedUser.username}
  const quizParams = {accessToken : params?.accessToken, order: params?.order}
  dispatch(userDetailsByUsername(userParams))
  dispatch(fetchQuiz(quizParams))

  return response.data
})

export const appQuizSlice = createSlice({
  name: 'quiz',
  initialState: {
    quiz: [],
    categories: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories = action.payload.Data
    })
    builder.addCase(fetchQuiz.fulfilled, (state, action) => {
      state.quiz = action.payload.Data
    })
  }
})

export default appQuizSlice.reducer