// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers} from 'redux'
import storage from "redux-persist/lib/storage"
import {persistStore, persistReducer} from "redux-persist"

// ** Reducers
import theme from '../store/apps/theme'
import auth from '../store/apps/auth'
import events from '../store/apps/events'
import user from '../store/apps/user'
import category from '../store/apps/category'
import suggestion from '../store/apps/suggestion'
import highlight from '../store/apps/highlight'
import news from '../store/apps/news'
import quiz from '../store/apps/quiz'
import toolbox from '../store/apps/toolbox'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["auth"]
}

const rootReducer = combineReducers({
  theme,
  auth,
  events,
  user,
  category,
  suggestion,
  highlight,
  news,
  quiz,
  toolbox,
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  })
})

export const persistor = persistStore(store);
