import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../helpers/apiHelper';
import { userDetailsByUsername } from '../auth';

// ** Axios Imports
import axios from 'axios'

// ** Fetch Users
export const fetchData = createAsyncThunk('users/fetchData', async (params) => { 
  
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken}
  const response = await api.get(`api/users/list?max_results=${params.max_results}&order=${params.order}&orderBy=${params.orderBy}`)

  return response.data;
})

// ** User Details
export const userDetails = createAsyncThunk('users/userDetails', async (params, { getState, dispatch }) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken}
  const response = await api.get(`api/users/details/${params.id}`)
 
  return response.data
})

// ** User History
export const userHistory = createAsyncThunk('users/userHistory', async (params, { getState, dispatch }) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken}
  const response = await api.get(`api/users/history/${params.id}`)
 
  return response.data
})


// ** Change Category
export const changeCategory = createAsyncThunk('users/changeCategory', async (params, { getState, dispatch }) => {
  
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken}
  const config = { headers: { "Content-Type": "multipart/form-data" }};

  const response = await api.post('api/users/changeCategory', params, config)

  const userParams = {accessToken : params.accessToken, username: getState().auth.loggedUser.username}

  dispatch(userDetailsByUsername(userParams))

  return response.data
})

// ** RemoveCategory
export const removeCategory = createAsyncThunk('users/removeCategory', async (params, { getState, dispatch }) => {
  
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken}
  const config = { headers: { "Content-Type": "multipart/form-data" }};

  const response = await api.post('api/users/removeCategory', params, config)

  const userParams = {accessToken : params.accessToken, username: getState().auth.loggedUser.username}

  dispatch(userDetailsByUsername(userParams))

  return response.data
})

// ** Change Pin
export const changePin = createAsyncThunk('users/changePin', async (params, { getState, dispatch }) => {
  
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken}
  const config = { headers: { "Content-Type": "multipart/form-data" }};

  const response = await api.post('api/users/changePin', params, config)

  const userParams = {accessToken : params.accessToken, username: getState().auth.loggedUser.username}

  dispatch(userDetailsByUsername(userParams))

  return response.data
})

// ** Change Nickanme
export const changeNickname = createAsyncThunk('users/changeNickname', async (params, { getState, dispatch }) => {
  
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken}
  const config = { headers: { "Content-Type": "multipart/form-data" }};

  const response = await api.post('api/users/changeNickname', params, config)

  const userParams = {accessToken : params.accessToken, username: getState().auth.loggedUser.username}

  dispatch(userDetailsByUsername(userParams))

  return response.data
})

// ** Change Avatar
export const changeAvatar = createAsyncThunk('users/changeAvatar', async (params, { getState, dispatch }) => {
  
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken}
  const config = { headers: { "Content-Type": "multipart/form-data" }};

  const response = await api.post('api/users/changeAvatar', params, config)

  const userParams = {accessToken : params.accessToken, username: getState().auth.loggedUser.username}

  dispatch(userDetailsByUsername(userParams))

  return response.data
})

export const appUsersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    history: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.data = action.payload.Data
    })
    builder.addCase(userHistory.fulfilled, (state, action) => {
      state.history = action.payload.Data
    })
  }
})

export default appUsersSlice.reducer
