import React, { useState, useEffect } from "react";
import { measures } from "config/measures";
import { lightTheme } from "config/colors";
import { ReactComponent as AguasdoNorteLogo } from "assets/images/logos/AguasDoNorteLogo.svg";
import { ReactComponent as Facebook } from "assets/images/home/facebook.svg";
import { Box } from "@mui/material";
import { Stack, Typography } from "@mui/material";
import Linkedin from "mdi-material-ui/Linkedin";
import Youtube from "mdi-material-ui/Youtube";
import moment from 'moment';

const Footer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleMouseEnter = (id) => {
    if (id) {
      document.getElementById(id).style.transition = "all .2s";
      document.getElementById(id).style.cursor = "pointer";
      document.getElementById(id).style.fill = lightTheme?.night_blue;
      document.getElementById(id).style.stroke = lightTheme?.night_blue;
    }
  };
  const handleMouseLeave = (id) => {
    if (id) {
      document.getElementById(id).style.transition = "all .2s";
      document.getElementById(id).style.cursor = "pointer";
      document.getElementById(id).style.fill = lightTheme?.blue_grey;
      document.getElementById(id).style.stroke = lightTheme?.blue_grey;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  return (
    <Box sx={{ height: "100%", m: 0, p: 0 }}>
      {/* // <Box sx={ windowWidth < 992 && windowWidth >= 768 ? { height: "10%"} : { height: "10%", m:0, p:0}}> */}
      <Box
        sx={{
          background: "white",
          borderRadius: 3,
          height: "100%",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "100%", mx: 2 }}
        >
          <img
                src="../../../assets/images/logos/AdNorte.jpg"
                alt="AdNorte"
            style={
              windowWidth >= 768
                ? { height: "2rem", width: "auto" }
                : { height: "2rem", width: "auto" }
            }
          />
          {windowWidth > 768 && (
            <Typography variant="body2">
              © {moment(new Date()).format('yyyy')} Águas do Norte. Todos os direitos reservados
            </Typography>
          )}
          <Stack direction="row" spacing={1}>
            <a
              href="https://www.facebook.com/aguasdonorte"
              target="_blank"
              rel="noreferrer"
            >
              <Facebook
                style={styles.socials}
                id="facebook"
                onMouseEnter={(e) => {
                  handleMouseEnter(e?.target?.id);
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(e?.target?.id);
                }}
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCYJmtSmv0jRTlelAzS2hSyQ"
              target="_blank"
              rel="noreferrer"
            >
              <Youtube
                id="youtube"
                style={styles.socials}
                onMouseEnter={(e) => {
                  handleMouseEnter(e?.target?.id);
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(e?.target?.id);
                }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/12634619"
              target="_blank"
              rel="noreferrer"
            >
              <Linkedin
                style={styles.socials}
                id="linkedin"
                onMouseEnter={(e) => {
                  handleMouseEnter(e?.target?.id);
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(e?.target?.id);
                }}
                fontSize="medium"
              />
            </a>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

const styles = {
  card: {
    borderRadius: `${measures?.border_radius}`,
    background: `${lightTheme?.cards}`,
    width: "100%",
    margin: "0",
  },

  socials: {
    height: "20px",
    fill: lightTheme?.blue_grey,
    stroke: lightTheme?.blue_grey,
  },
};

export default Footer;
