import { lightTheme, darkTheme } from '../../../config/colors';

function getTheme(theme) {

    switch(theme){
      case "lightTheme":
        return lightTheme;
      case "darkTheme":
        return darkTheme;
      default:
        return lightTheme;
    }
}

export default getTheme;
