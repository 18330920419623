import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../../../helpers/apiHelper';

// ** User Login
export const login = createAsyncThunk('auth/login', async (params) => { 
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  }
  const response = await api.post(`oauth/v2/token`, {
    username:params.username,
    password:params.password,
    client_id:process.env.REACT_APP_CLIENT_ID,
    client_secret:process.env.REACT_APP_CLIENT_SECRET,
    grant_type:"password",
  }, config)

  return response.data;
})

// ** user Regist 
export const regist = createAsyncThunk('auth/regist', async (params) => { 
  const values = params
  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  }
  const response = await api.get(`confirmAccount/checkUser?email=${values?.email}`, {
    values
  }, config)

  return response.data;
})

// ** User Login with Pin
export const loginWithPin = createAsyncThunk('auth/login', async (params) => { 
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  }
  const response = await api.post(`oauth/v2/token`, {
    username:params.username,
    pin:params.pin,
    client_id:process.env.REACT_APP_CLIENT_ID,
    client_secret:process.env.REACT_APP_CLIENT_SECRET,
    grant_type:"pin",
  }, config)

  return response.data;
})

// ** user Recover Pin 
export const recoverPin = createAsyncThunk('auth/recoverPin', async (params) => { 
  const values = params?.values
  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  }
  const response = await api.get(`auth/recoverPin/?email=${values?.email}`, {
    values
  }, config)

  return response.data;
})

// ** User Details By Username
export const userDetailsByUsername = createAsyncThunk('auth/userDetailsByUsername', async (params, { getState, dispatch }) => {
  api.defaults.headers.common = {'Authorization': 'Bearer ' + params.accessToken}
  const response = await api.get(`api/users/detailsByUsername/${params.username}`)
  
  return response.data
})

// ** Logout
export const logout = createAsyncThunk('auth/logout', async (params, { getState, dispatch }) => {
  return []
})

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    credentials: [],
    loggedUser: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.credentials = action.payload
    })
    builder.addCase(userDetailsByUsername.fulfilled, (state, action) => {
      state.loggedUser = action.payload.Data
    })
    builder.addCase(logout.fulfilled, (state, action) => {
      state.loggedUser = action.payload
      state.credentials = action.payload
    })
  }
})

export default authSlice.reducer
