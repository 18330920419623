import React from 'react';
import {Button} from 'reactstrap';
import { measures } from '../../../config/measures';
import { connect } from 'react-redux';
import getTheme from '../theme';



const SubmitButton = ({height,width,text,request,submit,theme}) => {
  
  const styles = {
    submitButton : {
      margin: "0",
      padding: "0",
      width: `${width}`,
      height: `${height}`,
      border: "0",
      borderRadius: '8px',
      background: `${getTheme(theme)?.button_gradient1}`,
      fontWeight: "400",
      letterSpacing: `${measures?.letter_spacing}`,
    },
  }
    
    return (
       <>
         <Button className='shadow-none' style={styles.submitButton} onClick={request} type={submit ? "submit" : "button"}>{text}</Button>
       </>      
    );
}
const mapStateToProps = (state) => {
  return {
    theme: state?.Customizer?.theme
  }
}

export default connect(mapStateToProps)(SubmitButton);
